$(document).ready(function () {
  var ANIMATION_TIMEOUT = '100'
  //mtxc_lu();
  var expdate = new Date(new Date().getTime() + (24 * 60 * 60 * 1000 * 365))
  var url = document.location.origin

  var annonce_banner = '.announce-banner'
  var annonce_banner_close = '.annonce-box .uk-alert-close'
  var announce_cookie_name = 'usa_annonce'

  var fraud_banner = '.cn-fraud-banner'
  var fraud_banner_close = '.annonce-box .cn-fraud-banner-close'
  var fraud_cookie_name = 'cn_fraud_banner_close'

  // Open and close pop-up signup
  $('#modal_open,.modal_open').click(function(event) {
    event.preventDefault();
    $('#signup').addClass('uk-open').show();
    $('html').addClass('uk-modal-page');
  });

  $('.close').click(function() {
    $('.uk-modal').removeClass('uk-open').hide();
    $('html').removeClass('uk-modal-page');
  });

  $(annonce_banner_close).click(function() {
    document.cookie = announce_cookie_name + "=close; path="+ url +"; expires=" + expdate + "";
    $(annonce_banner).slideUp(ANIMATION_TIMEOUT);
  });
  if (GetCookie(announce_cookie_name) !== 'close') $(annonce_banner).slideDown(ANIMATION_TIMEOUT);

  $(fraud_banner_close).click(function() {
    document.cookie = fraud_cookie_name + "=close; path="+ url +"; expires=" + expdate + "";
    $(fraud_banner).slideUp(ANIMATION_TIMEOUT);
  });
  if (GetCookie(fraud_cookie_name) !== 'close') $(fraud_banner).slideDown(ANIMATION_TIMEOUT);

  var path = location.pathname.split('/')[2];

  if (path !== '') {
    $('.js-local-switch a').each(function(index, el) {
      var link = el.href;
      $(this).attr('href', link+=path);
    });
  }

  $('.country_toggle').on('click', function() {
    $('.country_wrapp').toggleClass('open');
  });
  $('.map_box').on('click touchend', function() {
    $('.country_wrapp').removeClass('open');
  });
  $('.faq .uk-parent > a').on('click', function(event) {
    event.preventDefault();
    $(this).closest('.uk-parent').find('.uk-nav-sub').toggleClass('uk-open');
  });

  // Stiky menu
  var HeaderTop = $('.header').offset().top;

  $(window).scroll(function(){
    if( $(window).scrollTop() > HeaderTop ) {
      $('.header').addClass('stiky');
      $('.wrapper').addClass('stiked');
    } else {
      $('.header').removeClass('stiky');
      $('.wrapper').removeClass('stiked');
    }
  });

  $('.customers_chat_item').shuffle();

});

/*
 =============================
 FB modal
 ==============================
*/

// Показываем кнопки fb
(function (d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.0";
  fjs.parentNode.insertBefore(js, fjs);
}
(document, 'script', 'facebook-jssdk'));

// Счетчик показа страницы с помощью cookie
function getCookieVal(offset) {
  var endstr = document.cookie.indexOf(";", offset);

  if (endstr == -1)
    endstr = document.cookie.length;
  return unescape(document.cookie.substring(offset, endstr));
}

function GetCookie(name) {
  var arg = name + "=";
  var alen = arg.length;
  var clen = document.cookie.length;
  var i = 0;

  while (i < clen) {
    var j = i + alen;

    if (document.cookie.substring(i, j) == arg)
      return getCookieVal(j);

    i = document.cookie.indexOf(" ", i) + 1;

    if (i == 0)
      break;
  }
  return null;
}

function SetCookie(name, value) {
  var argv = SetCookie.arguments;
  var argc = SetCookie.arguments.length;
  var expires = (2 < argc) ? argv[2] : null;
  var path = (3 < argc) ? argv[3] : null;
  var domain = (4 < argc) ? argv[4] : null;
  var secure = (5 < argc) ? argv[5] : false;

  document.cookie = name + "=" + escape(value) +
    ((expires == null) ? "" : ("; expires=" + expires.toGMTString())) +
    ((path == null) ? "" : ("; path=" + path)) +
    ((domain == null) ? "" : ("; domain=" + domain)) +
    ((secure == true) ? "; secure" : "");
}


// Видео для страницы how it works
$(function () {
  if ($(".youtube")) {
    $(".youtube").each(function () {
      // Зная идентификатор видео на YouTube, легко можно найти его миниатюру
      $(this).css('background-image', 'url(http://i.ytimg.com/vi/' + this.id + '/sddefault.jpg)');

      // Добавляем иконку Play поверх миниатюры, чтобы было похоже на видеоплеер
      $(this).append($('<div/>', {'class': 'play'}));

      $(document).delegate('#' + this.id, 'click', function () {
        // создаем iframe со включенной опцией autoplay
        var iframe_url = "https://www.youtube.com/embed/" + this.id + "?autoplay=1&autohide=1";
        if ($(this).data('params')) iframe_url += '&' + $(this).data('params');

        // Высота и ширина iframe должны быть такими же, как и у родительского блока
        var iframe = $('<iframe/>', {
          'frameborder': '0',
          'src': iframe_url,
          'width': $(this).width(),
          'height': $(this).height()
        })

        // Заменяем миниатюру HTML5 плеером с YouTube
        $(this).hide();
        $(this).closest('.video-wrapper').append(iframe);

      });
    });
  }

  $('.video-guide .uk-nav a').on('click', function() {
    $('#video_guides iframe').remove();
    $('.youtube').show();
  });
});



// Сброс показов страницы
function ResetCounts() {
  var expdate = new Date();

  expdate.setTime(expdate.getTime() + (24 * 60 * 60 * 1000 * 365));
  visit = 0;
  SetCookie("visit", visit, expdate, "/", null, false);
  history.go(0);
}


// Сброс кнопки fb
function ResetCountsFb() {
  var expdate = new Date();

  expdate.setTime(expdate.getTime() + (24 * 60 * 60 * 1000 * 365));
  fbButt = 0;
  SetCookie("fbButt", fbButt, expdate, "/", null, false);
  history.go(0);
}


// Перемешиваем отзывы при загрузке страницы. Все что больше 3-х скрываем css
(function(jQuery){
  jQuery.fn.shuffle = function(){
    var allElems = this.get();

    var getRandom = function(max){
      return Math.floor(Math.random() * max);
    }

    var shuffled = jQuery.map(allElems, function(){
      var random = getRandom(allElems.length),
      randEl = jQuery(allElems[random]).clone(true)[0];
      allElems.splice(random, 1);
      return randEl;
    });

    this.each(function(i){
      jQuery(this).replaceWith(jQuery(shuffled[i]));
    });

    return jQuery(shuffled);
  };
})(jQuery);

